import {React, useState} from 'react';
import './Contact.css';

function Contact() { // Récupérer showContact en tant que prop
  // eslint-disable-next-line
    const [status, setStatus] = useState("Submit");
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus("Sending...");
      const { name, email, message } = e.target.elements;
      let details = {
        name: name.value,
        email: email.value,
        message: message.value,
      };
      let response = await fetch("https://portfolio-1tas.onrender.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Submit");
      let result = await response.json();

      if(result){
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
          input.value = ''; // Efface la valeur de chaque champ input et textarea
        });

        const button = document.getElementById('submit');
        button.innerHTML = 'Message envoyé !';
        
        // Rétablir le texte du bouton après quelques secondes
        setTimeout(() => {
          button.innerHTML = 'Envoyer';
        }, 2000);
      }
      

      return result;
    };

    return (
        <div className='contact'>
          <div className="contact-title">
            <span>C</span><span>O</span><span>N</span><span>T</span><span>A</span><span>C</span><span>T</span><span>E</span><span>Z</span><span>&ensp;</span><span>M</span><span>O</span><span>I</span>
          </div>
          <div className="dot dot-one"></div>
            <div className="dot dot-two"></div>
            <div className="dot dot-three"></div>
            <div className="dot dot-four"></div>
            <div className="dot dot-five"></div>

            <form onSubmit={handleSubmit}>
                <input id='email' type="email" name='name' placeholder='Email*' required/>
                <input id='name' type="text" name='email' placeholder='Nom*' required/>
                <button id='submit' type='submit'>Envoyer</button>
                <textarea id='message' placeholder='Message*' required />
            </form>
        </div>
        
    );
}

export default Contact;
