import React, { useState, useRef } from 'react';
import './Project.css';
import projects from '../../data/projects.json';

function Project() {
    // Gère l'affichage d'infos supplémentaires liées à chaque projet
    const [activeProjectId, setActiveProjectId] = useState(null);

    // Référence pour la section more-info
    const moreInfoRef = useRef(null);

    // Fonction pour gérer le clic sur une carte de projet
    const handleMoreInfoClick = (projectId) => {
        setActiveProjectId(projectId === activeProjectId ? null : projectId);

        // Scroll vers la section 'more-info'
        if (moreInfoRef.current) {
            moreInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div className="page-wrapper">
            <div className="projects-main">
                <div className="projects-title">
                    <span>P</span>
                    <span>R</span>
                    <span>O</span>
                    <span>J</span>
                    <span>E</span>
                    <span>T</span>
                    <span>S</span>
                </div>
                <div className="projects-block">
                    <div id='projects-wrapper' className= "projects-wrapper">
                        {projects.map((project) => (
                            <div className='project-card' key={project.id} onClick={() => handleMoreInfoClick(project.id)} >
                                <img src={project.cover} alt={project.title} />
                                <div className="project-info">
                                    <h1>{project.title}</h1>
                                    <p>{project.description}</p>
                                    <span className='more-info' onClick={(e) => { e.preventDefault(); handleMoreInfoClick(project.id); }}>
                                        Plus d'infos
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div ref={moreInfoRef} id='more-info' className={`more-info-content ${activeProjectId ? 'more-info-visible' : 'more-info-hidden'}`}>
                {activeProjectId && (
                    <div className="info-text">
                        <span className='link' onClick={() => handleMoreInfoClick(activeProjectId)}>Retour</span>
                        <div className="info-grid">
                            <div className="problems">
                                <h3>Description :</h3>
                                <p>{projects.find(project => project.id === activeProjectId).more}</p>
                            </div>
                        </div>
                        <div className='developped-skills'>
                            <h3>Technologies :</h3>
                            <div className="skill-icon">
                                {projects.find(project => project.id === activeProjectId).skills.img.map((imageUrl, index) => (
                                    <div key={index}>
                                        <img src={imageUrl} alt="Capture du projet" />
                                        <p>{projects.find(project => project.id === activeProjectId).skills.name[index]}</p>
                                    </div>
                                ))}
                            </div>
                        </div>	
                        <a className='link see-site' href={projects.find(project => project.id === activeProjectId).url} target='_blank' rel="noreferrer">Voir le site</a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Project;
