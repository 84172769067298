import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor"

import Home from "./pages/Home";
import './App.css';

function App() {

  return (
    <div className="container">
      <AnimatedCursor 
      innerSize={10}
      outerSize={0}
      color='255, 255, 255'
      outerAlpha={1}
      innerScale={3}
      trailingSpeed={3}
      innerStyle={{
        filter: 'blur(3px)'
      }}
      clickables={['a']}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
